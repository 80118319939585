var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2 complete-profile"},[(!_vm.showContent)?_c('div',[_c('div',{staticClass:"d-flex justify-content-center mb-1"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)]):_vm._e(),(_vm.showContent)?_c('div',[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[(_vm.showRegistrationForm)?_c('div',[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" ZNAPSHOT ")])]),_c('b-card-title',{staticClass:"mb-1 text-center"},[_vm._v(" Adventure starts here 🚀 ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Hi, You have been invited to create a user on ZNAPSHOT App. Kindly verify the below details. ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',[_c('b-avatar',{attrs:{"size":"170px","src":_vm.userData.portraitPhoto
                        ? _vm.portraitPhoto
                        : require('@/assets/images/logo/znapshot-logo-simple.png')}}),_c('span',{staticClass:"d-flex justify-content-end mt-n3",on:{"click":_vm.selectImage}},[_c('feather-icon',{staticClass:"edit-profile-pic-icon",attrs:{"icon":"EditIcon","size":"30"}})],1)],1)]),_c('b-form-group',{staticClass:"portraitPhoto",attrs:{"label":"Image","label-for":"portraitPhoto"}},[_c('validation-provider',{attrs:{"name":"Image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"profileImage","accept":"image/jpeg, image/png, image/jpg","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":_vm.changeImage},model:{value:(_vm.userData.portraitPhoto),callback:function ($$v) {_vm.$set(_vm.userData, "portraitPhoto", $$v)},expression:"userData.portraitPhoto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1171763318)})],1),_c('b-form-group',{attrs:{"label":"Full Name","label-for":"fullname"}},[_c('validation-provider',{attrs:{"name":"Full Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fullname","state":errors.length > 0 ? false : null,"name":"register-fullname","placeholder":"johndoe"},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3473219960)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","name":"register-email","placeholder":"john@example.com","disabled":""},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})],1),_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country","label-for":"country"}},[_c('v-select',{attrs:{"options":_vm.allCountries,"state":errors.length > 0 ? false : null,"label":"name","reduce":function (country) { return country.id; },"clearable":false,"input-id":"country"},model:{value:(_vm.userData.countryId),callback:function ($$v) {_vm.$set(_vm.userData, "countryId", $$v)},expression:"userData.countryId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3560920681)}),_c('b-form-group',{attrs:{"label":"Contact Number","label-for":"contact_no"}},[_c('validation-provider',{attrs:{"name":"Contact Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contact_no","name":"register-contact-number","placeholder":"+92 xxx xxxxxxx"},model:{value:(_vm.userData.contactNo),callback:function ($$v) {_vm.$set(_vm.userData, "contactNo", $$v)},expression:"userData.contactNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2765570230)})],1),(_vm.userData.userRoles.id===2)?_c('div',[_c('b-form-group',{attrs:{"label":"Short Description","label-for":"shortDescription"}},[_c('validation-provider',{attrs:{"name":"Short Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"shortDescription","state":errors.length > 0 ? false : null,"name":"register-shortDescription","placeholder":"I am ..."},model:{value:(_vm.userData.shortDescription),callback:function ($$v) {_vm.$set(_vm.userData, "shortDescription", $$v)},expression:"userData.shortDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3440871085)})],1),_c('b-form-group',{attrs:{"label":"Long Description","label-for":"longDescription"}},[_c('validation-provider',{attrs:{"name":"Long Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"longDescription","rows":"3","state":errors.length > 0 ? false : null,"name":"register-longDescription","placeholder":"I am ..."},model:{value:(_vm.userData.longDescription),callback:function ($$v) {_vm.$set(_vm.userData, "longDescription", $$v)},expression:"userData.longDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,20379189)})],1),_c('b-form-group',{attrs:{"label":"Website","label-for":"website"}},[_c('validation-provider',{attrs:{"name":"Website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"website","state":errors.length > 0 ? false : null,"name":"register-website","placeholder":"www."},model:{value:(_vm.userData.webSite),callback:function ($$v) {_vm.$set(_vm.userData, "webSite", $$v)},expression:"userData.webSite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,546688400)})],1),_c('b-form-group',{attrs:{"label":"Instagram","label-for":"instagram"}},[_c('validation-provider',{attrs:{"name":"Instagram","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"instagram","state":errors.length > 0 ? false : null,"name":"register-instagram","placeholder":"Instagram"},model:{value:(_vm.userData.insta),callback:function ($$v) {_vm.$set(_vm.userData, "insta", $$v)},expression:"userData.insta"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,662608683)})],1),_c('b-form-group',{attrs:{"label":"Facebook","label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","state":errors.length > 0 ? false : null,"name":"register-facebook","placeholder":"Facebook"},model:{value:(_vm.userData.facebook),callback:function ($$v) {_vm.$set(_vm.userData, "facebook", $$v)},expression:"userData.facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3422261682)})],1),_c('b-form-group',{attrs:{"label":"Twitter","label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"Twitter","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","state":errors.length > 0 ? false : null,"name":"register-twitter","placeholder":"Twitter"},model:{value:(_vm.userData.twitter),callback:function ($$v) {_vm.$set(_vm.userData, "twitter", $$v)},expression:"userData.twitter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3490537362)})],1)],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,311988903)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Sign up ")])],1)],1)],1):(!_vm.showRegistrationForm && !_vm.showGreetings)?_c('div',[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" ZNAPSHOT ")])]),_c('b-card-title',{staticClass:"mb-1 text-center"},[_vm._v(" This Invitation is either Invalid or Expired ")])],1):_vm._e(),(_vm.showGreetings)?_c('div',[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" ZNAPSHOT ")])]),_c('b-card-title',{staticClass:"mb-1 text-center"},[_vm._v(" Thank You. ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Kindly click "),_c('a',{attrs:{"href":"www.google.com","target":"_blank"}},[_vm._v("here")]),_vm._v(" to download our Application ")])],1):_vm._e()])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }