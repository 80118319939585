import axios from '../libs/axios'

const roleOptions = [
  { label: 'Admin', value: 1 },
  { label: 'Creator', value: 2 },
]

const statusOptions = [
  { label: 'Pending', value: 0 },
  { label: 'Accepted', value: 1 },
  { label: 'All', value: 2 },
]

const planOptions = [
  { label: 'Pending', value: 0 },
  { label: 'Accepted', value: 1 },
  { label: 'All', value: 2 },
]

// *===============================================---*
// *--------- UI ---------------------------------------*
// *===============================================---*

const resolveUserRoleVariant = role => {
  if (role === 'USER') return 'primary'
  if (role === 'CREATOR') return 'info'
  if (role === 'ADMIN') return 'success'
  // if (role === 'editor') return 'info'
  // if (role === 'admin') return 'danger'
  return 'primary'
}

const resolveUserRoleIcon = role => {
  if (role === 'USER') return 'UserIcon'
  if (role === 'CREATOR') return 'SettingsIcon'
  // if (role === 'maintainer') return 'DatabaseIcon'
  // if (role === 'editor') return 'Edit2Icon'
  if (role === 'ADMIN') return 'ServerIcon'
  return 'UserIcon'
}

const resolveUserStatusVariant = status => {
  if (status === 'Disabled') return 'danger'
  if (status === 'Enabled') return 'success'
  if (status === 'Pending') return 'warning'
  return 'primary'
}

const getPaginatedUsers = queryParams => axios.post('/users/get_all_creators', queryParams)
const fetchUserById = id => axios.get(`/apps/user/users/${id}`)
const addNewUser = user => axios.post('/users/create_user_in_firebase', user)
const fetchAllCountries = () => axios.get('/users/get_all_countries')
const toggleUser = userId => axios.get(`/users/toggle_user_status/${userId}`)
const deleteUser = userId => axios.delete(`/users/${userId}`)
const getUserById = userId => axios.get(`/users/${userId}`)
export {
  roleOptions,
  statusOptions,
  planOptions,
  resolveUserRoleVariant,
  resolveUserRoleIcon,
  resolveUserStatusVariant,
  getPaginatedUsers,
  fetchUserById,
  addNewUser,
  fetchAllCountries,
  toggleUser,
  deleteUser,
  getUserById,
}
