<template>
  <div class="auth-wrapper auth-v1 px-2 complete-profile">
    <div v-if="!showContent">
      <div class="d-flex justify-content-center mb-1">
        <b-spinner label="Loading..." />
      </div>
    </div>
    <div v-if="showContent">
      <div class="auth-inner py-2">
        <b-card class="mb-0">
          <div v-if="showRegistrationForm">
            <b-link class="brand-logo">
              <h2 class="brand-text text-primary ml-1">
                ZNAPSHOT
              </h2>
            </b-link>

            <b-card-title class="mb-1 text-center">
              Adventure starts here 🚀
            </b-card-title>
            <b-card-text class="mb-2 text-center">
              Hi, You have been invited to create a user on ZNAPSHOT App. Kindly
              verify the below details.
            </b-card-text>

            <!-- form -->
            <validation-observer ref="registerForm">
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent
              >
                <div class="d-flex justify-content-center">
                  <div>
                    <b-avatar
                      size="170px"
                      :src="
                        userData.portraitPhoto
                          ? portraitPhoto
                          : require('@/assets/images/logo/znapshot-logo-simple.png')
                      "
                    />
                    <span
                      class="d-flex justify-content-end mt-n3"
                      @click="selectImage"
                    >
                      <feather-icon
                        class="edit-profile-pic-icon"
                        icon="EditIcon"
                        size="30"
                      />
                    </span>
                  </div>
                </div>
                <!-- username -->
                <b-form-group
                  class="portraitPhoto"
                  label="Image"
                  label-for="portraitPhoto"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Image"
                    rules="required"
                  >
                    <b-form-file
                      id="profileImage"
                      v-model="userData.portraitPhoto"
                      accept="image/jpeg, image/png, image/jpg"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      @change="changeImage"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- username -->
                <b-form-group
                  label="Full Name"
                  label-for="fullname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Full Name"
                    rules="required"
                  >
                    <b-form-input
                      id="fullname"
                      v-model="userData.name"
                      :state="errors.length > 0 ? false : null"
                      name="register-fullname"
                      placeholder="johndoe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    name="register-email"
                    placeholder="john@example.com"
                    disabled
                  />
                </b-form-group>

                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <b-form-group
                    label="Country"
                    label-for="country"
                  >
                    <v-select
                      v-model="userData.countryId"
                      :options="allCountries"
                      :state="errors.length > 0 ? false : null"
                      label="name"
                      :reduce="(country) => country.id"
                      :clearable="false"
                      input-id="country"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <!-- phone no -->
                <b-form-group
                  label="Contact Number"
                  label-for="contact_no"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Contact Number"
                    rules="required"
                  >
                    <b-form-input
                      id="contact_no"
                      v-model="userData.contactNo"
                      name="register-contact-number"
                      placeholder="+92 xxx xxxxxxx"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <div v-if="userData.userRoles.id===2">
                  <b-form-group
                    label="Short Description"
                    label-for="shortDescription"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Short Description"
                      rules="required"
                    >
                      <b-form-input
                        id="shortDescription"
                        v-model="userData.shortDescription"
                        :state="errors.length > 0 ? false : null"
                        name="register-shortDescription"
                        placeholder="I am ..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- Long text -->
                  <b-form-group
                    label="Long Description"
                    label-for="longDescription"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Long Description"
                      rules="required"
                    >
                      <b-form-textarea
                        id="longDescription"
                        v-model="userData.longDescription"
                        rows="3"
                        :state="errors.length > 0 ? false : null"
                        name="register-longDescription"
                        placeholder="I am ..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- Website -->
                  <b-form-group
                    label="Website"
                    label-for="website"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Website"
                      rules="required"
                    >
                      <b-form-input
                        id="website"
                        v-model="userData.webSite"
                        :state="errors.length > 0 ? false : null"
                        name="register-website"
                        placeholder="www."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- Instagram -->
                  <b-form-group
                    label="Instagram"
                    label-for="instagram"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Instagram"
                      rules="required"
                    >
                      <b-form-input
                        id="instagram"
                        v-model="userData.insta"
                        :state="errors.length > 0 ? false : null"
                        name="register-instagram"
                        placeholder="Instagram"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- Facebook -->
                  <b-form-group
                    label="Facebook"
                    label-for="facebook"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Facebook"
                      rules="required"
                    >
                      <b-form-input
                        id="facebook"
                        v-model="userData.facebook"
                        :state="errors.length > 0 ? false : null"
                        name="register-facebook"
                        placeholder="Facebook"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- Twitter -->
                  <b-form-group
                    label="Twitter"
                    label-for="twitter"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Twitter"
                      rules="required"
                    >
                      <b-form-input
                        id="twitter"
                        v-model="userData.twitter"
                        :state="errors.length > 0 ? false : null"
                        name="register-twitter"
                        placeholder="Twitter"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <!-- Short text -->

                <!-- password -->
                <b-form-group
                  label="Password"
                  label-for="password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password"
                        v-model="userData.password"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <!--            <b-form-group>-->
                <!--              <b-form-checkbox-->
                <!--                id="register-privacy-policy"-->
                <!--                v-model="status"-->
                <!--                name="checkbox-1"-->
                <!--              >-->
                <!--                I agree to-->
                <!--                <b-link>privacy policy & terms</b-link>-->
                <!--              </b-form-checkbox>-->
                <!--            </b-form-group>-->

                <!-- submit button -->
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  @click.prevent="validationForm"
                >
                  Sign up
                </b-button>
              </b-form>
            </validation-observer>
          </div>
          <div v-else-if="!showRegistrationForm && !showGreetings">
            <b-link class="brand-logo">
              <h2 class="brand-text text-primary ml-1">
                ZNAPSHOT
              </h2>
            </b-link>
            <b-card-title class="mb-1 text-center">
              This Invitation is either Invalid or Expired
            </b-card-title>
          </div>
          <div v-if="showGreetings">
            <b-link class="brand-logo">
              <h2 class="brand-text text-primary ml-1">
                ZNAPSHOT
              </h2>
            </b-link>
            <b-card-title class="mb-1 text-center">
              Thank You.
            </b-card-title>
            <b-card-text class="mb-2 text-center">
              Kindly click <a
                href="www.google.com"
                target="_blank"
              >here</a> to
              download our Application
            </b-card-text>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  BAvatar,
  BFormFile,
  BFormTextarea,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Vue from 'vue'
import { fetchAllCountries } from '@/api/users'
import { getFormDataFromJSON } from '@/api/common'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAvatar,
    BFormFile,
    vSelect,
    BFormTextarea,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      showContent: false,
      showRegistrationForm: false,
      showGreetings: false,
      emailInviteHash: '',
      portraitPhoto: null,
      userData: {
        email: '',
        id: 0,
        name: '',
        contactNo: '',
        countryId: '',
        password: '',
        shortDescription: '',
        longDescription: '',
        webSite: '',
        facebook: '',
        insta: '',
        twitter: '',
        portraitPhoto: null,
      },
      allCountries: [],
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async created() {
    if (this.$route.query.request !== undefined) {
      this.userData.emailInviteHash = this.$route.query.request
      await this.fetchUserFromHash()
      const { data } = await fetchAllCountries()
      this.allCountries = data
      this.showContent = true
    }
  },
  methods: {
    validationForm() {
      if (!this.userData.portraitPhoto) {
        this.$swal('Error!', 'Please select a cover photo', 'warning')
      } else {
        this.$refs.registerForm.validate().then(success => {
          if (success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Submitted',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.createUser()
          }
        })
      }
    },
    async fetchUserFromHash() {
      const { data } = await this.$http.get(
        `/users/get_user_from_hash/${this.userData.emailInviteHash}`,
      )
      if (data !== null && data !== '') {
        console.log(data)
        this.userData = data
        this.userData.name = data.fullName
        this.showRegistrationForm = true
        this.userData.countryId = data.country ? data.country.id : null
      }
    },
    selectImage() {
      document.getElementById('profileImage').click()
    },
    changeImage(event) {
      const obj = event.target.files[0]
      Vue.set(this.userData, 'portraitPhoto', obj)
      this.portraitPhoto = URL.createObjectURL(obj)
    },
    async createUser() {
      const loading = this.$swal.fire({
        title: 'Please Wait! We are processing the data.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })
      const resp = await this.$http.put(
        `${this.$store.state.app.mainAppURL}/users/update_invited_creator`,
        getFormDataFromJSON(this.userData),
      )
      loading.close()
      if (resp.status === 200) {
        this.$swal(
          'Congratulations!',
          'Your User Has Been Created. \n Kindly Download Our App To Use Our Services',
          'success',
        )
      } else {
        this.$swal(
          'Error!',
          'Something went wrong. Kindly contact Admin at admin@znapshot.com',
          'error',
        )
      }

      this.showRegistrationForm = false
      this.showGreetings = true
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.complete-profile {
  .edit-profile-pic-icon {
    cursor: pointer;
    z-index: +9;
  }

  .portraitPhoto {
    display: none !important;
  }
}
</style>
